.textareaContainer {
  position: relative;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  border-radius: 3px;
  border: 1.5px solid #f2f2f2;
}

.textarea {
  display: block;
  width: 100%;
  height: 100px;
  padding: 10px 20px 30px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

.textareaErrorStyle {
  border: 1.5px solid #ff0000;
}

.full-borders-textarea {
  background-color: var(--color-white);
  border: 1.5px solid var(--color-border-gray);
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  width: 444px;
  padding-left: 30px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.5029529389880952;
  text-align: left;
}

.textareaCounter {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  display: inline-block;
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
}

.maxLimitStyle {
  color: #ff0000;
}
