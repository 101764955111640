.brand-area {
  display: block;
  text-align: center;
}

.brand-area .brand-logo {
  height: 86px;
  display: block;
  margin: 0 auto;
}

.brand-area .brand-sub-logo {
  height: 120px;
}

.brand-logo {
  width: 60%;
}

.nav-links {
  display: block;
}

.nav-links .menu-link {
  display: block;
  color: #fff;
  padding: 14px 0 14px 20px;
  height: 21px;
  font-size: 12px;
  text-decoration: none;
}

.nav-links .menu-link span {
  position: relative;
  padding-left: 2px;
  vertical-align: middle;
}

/* Active/current link */
.nav-links .menu-link.active {
  background-color: #151516;
  color: #ffffff;
}

.nav-links .menu-link.sandbox-active {
  background-color: var(--color-prod);
  color: #ffffff;
}

.nav-links .menu-link.prod-active {
  background-color: var(--color-sandbox);
  color: #ffffff;
}

/* Links on mouse-over */
.nav-links .menu-link:hover:not(.active) {
  background-color: #06315a;
  color: #ffffff;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}
