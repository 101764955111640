.button {
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 2px;
}

.button:disabled {
  opacity: 0.5469889;
}

.btn-block {
  width: 100%;
  display: block;
  background-color: var(--button-default-color);
  color: var(--color-white);
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-inline {
  display: inline-block;
}

.btn-next {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 3px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 10px 34px;
  margin-top: 15px;
}

.btn-next-inverted {
  color: var(--color-primary);
  background-color: var(--color-white);
  border: 1px solid var(--color-primary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 34px;
}

.btn-transparent {
  color: var(--color-primary);
  background-color: var(--color-white);
  border: none;
  padding: 10px 34px;
}

.btn-next-inverted-danger {
  color: var(--color-danger);
  background-color: var(--color-white);
  border: 1.5px solid var(--color-danger);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 34px;
}
.btn-next-inverted-success {
  color: var(--color-success);
  background-color: var(--color-white);
  border: 1.5px solid var(--color-success);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 34px;
}

.btn-next-danger {
  color: var(--color-white);
  background-color: var(--color-danger);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 34px;
}

.btn-next-danger-rounded {
  color: var(--color-white);
  background-color: var(--color-danger);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 6px 20px;
  border-radius: 5px;
  margin: 2px;
}

.btn-next-success {
  color: var(--color-white);
  background-color: var(--color-success);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
}

.btn-next-success-rounded {
  color: var(--color-white);
  background-color: var(--color-success);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 6px 20px;
  border-radius: 5px;
  margin: 2px;
}

.btn-dark {
  color: var(--color-white);
  background-color: #151516;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 34px;
}

.btn-with-icon .text {
  position: relative;
  margin-left: 10px;
}
